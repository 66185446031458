import countDown from "../../../components/countDown";
import img1 from "../../../assets/img/site/1.png";
import img3 from "../../../assets/img/site/3.png";
import img4 from "../../../assets/img/site/4.png";
import img5 from "../../../assets/img/site/5.png";
import img6 from "../../../assets/img/site/6.png";
import {mapGetters} from "vuex";

export default {
  components: {
    countDown: countDown
  },
  data() {
    return {
      value1: 0,
      webSite: {},
      countDown: {},
      pageLang: 1,
      alts: {
        atlas: []
      },
      imgArray: [img1, img3, img4, img5, img6],
      actArray: [],
      actUrl: "",
      bannerStyle: {},
      carouse: {},
      carouseIdx: 0,
      coverAtlas: [],
      coverVideos: [],
      iconColor: {},
    };
  },
  watch: {
    $route() {
      this.pageLang = this.$route.query.lang === 1 ? 1 : 2;
    },
    getConfig() {
      this.initConfig();
    },
  },
  computed: {
    ...mapGetters(["getConfig"])
  },
  created() {

  },
  mounted(){
    this.initConfig();
    setInterval(()=>{
      this.carouselMove(1)
    },5000)
  },
  methods: {
    initConfig() {
      this.webSite = this.getConfig;
      console.log(this.webSite)
      for (let x of this.webSite.atlList) {
        if (x.type) {
          this.alts.atlas = x.atlas.length > 8 ? x.atlas.slice(0, 8) : x.atlas;
        }
      }
      this.setArticle(this.webSite);
      this.bannerStyle = this.getStyleConfig('banner');
      this.coverAtlas = this.getCoverAtlas('coverAtlas');
      console.log(111)
      console.log(this.coverAtlas)
      this.coverVideos = this.getCoverAtlas('coverVideos');
      this.iconColor = this.getStyleConfig('adDec');
    },
    openImgMod(url) {
      this.actUrl = url;
    },
    getAdList(type) {
      return this.pageLang === 1
        ? type === 1
          ? this.webSite.adList1 || []
          : this.webSite.adList2 || []
        : type === 1
          ? this.webSite.enAdList1 || []
          : this.webSite.enAdList2 || [];
    },
    testDate(date) {
      if (date === "") {
        return true;
      }
      let leftTime = new Date(date) - new Date();
      return leftTime <= 0;
    },
    getTimeSate(time) {
      if (time) {
        let leftTime = new Date(time) - new Date();
        return leftTime > 0;
      }
      return false;
    },
    linkTo(link) {
      if (!link) {
        return false;
      }
      if (link === "alts") {
        this.$router.push({ name: "alts" });
        return false;
      }
      if (link === "videos") {
        this.$router.push({ name: "videos" });
        return false;
      }
      if (link.length === 8) {
        for (let x of this.webSite.articleList) {
          if (link === x.link) {
            this.$router.push({ name: "article", query: { id: link } });
            return false;
          }
        }
        return false
      }
      if (link.length === 6) {
        this.$router.push({ name: "list", query: { id: link } });
        return false;
      }
      if (link === "list") {
        this.$router.push({ name: "list", query: { id: link } });
        return false;
      }
      window.open(link);
    },
    getTime(time, type) {
      if (!time) {
        return "";
      }
      let d = new Date(time);
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      let date = d.getDate();
      // let hour = d.getHours();
      // let minute = d.getMinutes();
      // let second = d.getSeconds();
      return type ? date : year + "-" + month;
    },
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    setArticle(item) {
      let actArray = [];
      let arr = [];
      let typeArray =
        this.pageLang === 1 ? item.coverArticle : item.enCoverArticle;
      let articleList = item.articleList;
      for (let x of articleList) {
        for (let y of typeArray) {
          x.num = Math.floor(Math.random() * 4);
          if (x.type === y) {
            if (x.top) {
              actArray.push(x);
            } else {
              arr.push(x);
            }
          }
        }
      }
      let sortLen = actArray.length;
      let arrLen = arr.length;
      let len = 6 - sortLen > arrLen ? arrLen : 6 - sortLen;
      actArray.push(...arr.slice(0, len));
      this.actArray = actArray;
    },
    getCoverAtlas(type){
      let arr = [];
      let fileType = this.getConfig[type];
      let list = type ===  "coverVideos" ? this.getConfig.videos : this.getConfig.album;
      // let list = type ===  "coverVideos" ? this.getConfig.videos : this.getConfig.coverAtlas;
      // if (fileType) {
      //   for (let x of list){
      //     if (x.atlas === fileType) {
      //       if (arr.length < 8) {
      //         arr.push(x)
      //       }
      //     }
      //   }
      // }
      for (let x of list){
          if (arr.length < 8) {
            arr.push(x)
          }
      }
      return arr
    },
    getStyleConfig(type,item) {
      if (type === "banner") {
        let bannerConfig = ( this.pageLang === 1 ? this.getConfig.bannerConfig.cn : this.getConfig.bannerConfig.en) || {};
        let type  =  bannerConfig.size === 2  ?  420 / 1200 : 336 / 1200;
        console.log(bannerConfig.type === 2  ? window.innerWidth * type + "px" : bannerConfig.size === 2 ? "420px" : "336px");
        return {
          width: bannerConfig.type === 2 ? window.innerWidth + "px": "1200px",
          height: bannerConfig.type === 2  ? window.innerWidth * type + "px" : bannerConfig.size === 2 ? "420px" : "336px"
        }
      }
      if (type === "carouse") {
        let bannerConfig = ( this.pageLang === 1 ? this.getConfig.bannerConfig.cn : this.getConfig.bannerConfig.en) || {};
        let w = bannerConfig.type === 2 ? window.innerWidth : 1200;
        let trackWidth = w * this.webSite.bannerList.length;
        return {
          width: `${ trackWidth }px`,
          transform: `translate3d(${ 0 -(w * this.carouseIdx)}px, 0px, 0px)`,
          transition: `transform 500ms 0s`
        };
      }
      if(type === "adDec") {
        let obj = {
          backgroundColor: this.getConfig.pageColor,
        };
        if (item) {
          let name = item.name.replace(/(^[ \t\n\r]+)|([ \t\n\r]+$)/g, '');
          obj.display = "block";
          if (item.date === "" && name === "") {
            obj.display = "none";
          }
        }
        return obj
      }
      if (type === "countType"){
        return this.getConfig.countdown.bgType === 2
      }
      if (type === "countDown") {
        return {
          width: this.getConfig.countdown.type === 2 ? "100%" : "1200px",
          backgroundColor: this.getConfig.countdown.bgColor || this.getConfig.pageColor
        }
      }
    },
    getConfigText(type) {
      let str = "";
      if (type === "countType") {
        str = this.getConfig.countdown.icon
      }
      return str
    },
    carouselMove(idx) {
      let num = this.carouseIdx + idx;
      this.carouseIdx = num > ( this.webSite.bannerList.length - 1 ) ? 0 : num < 0 ? this.webSite.bannerList.length - 1 : num;
    }
  }
};
