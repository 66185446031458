<template>
  <div class="pageMod">
    <div class="countDown" v-if="webSite.countdown && parseInt(webSite.countdown.offset) === 2 && webSite.countdown.switch" :style="getStyleConfig('countDown')">
      <countDown :time="webSite.countdown.time"></countDown>
      <img :src="getConfigText('countType')" alt="" v-if="getStyleConfig('countType')">
    </div>
    <div class="pageCarousel" :style="bannerStyle" v-if="bannerStyle.width">
      <div class="carouselGroup" :style="getStyleConfig('carouse')">
 
        <div class="carousel" 
         :style="bannerStyle" 
         v-for="(item, index) in pageLang ===1 ? webSite.bannerList: webSite.enBannerList" 
         :key="index"
         @click="linkTo(item.link)"
         >
          <!--<img :src="item.url" alt="" />-->
          <img v-lazy="item.url">
        </div>
      
      </div>
      <div class="leftArrow" @click="carouselMove(-1)">
        <Icon type="ios-arrow-dropleft-circle" size="40"/>
      </div>
      <div class="rightArrow" @click="carouselMove(1)">
        <Icon type="ios-arrow-dropright-circle" size="40" />
      </div>
    </div>
    <div class="countDown" v-if="webSite.countdown && parseInt(webSite.countdown.offset) === 3 && webSite.countdown.switch" :style="getStyleConfig('countDown')">
      <countDown :time="webSite.countdown.time"></countDown>
      <img :src="getConfigText('countType')" alt="" v-if="getStyleConfig('countType')">
    </div>
    <div class="cardGroup">
      <div class="pageCard" v-for="(item,index) in getAdList(1)"  :key="index" @click="linkTo(item.link)">
        <video controls="controls" v-if="item.video">
          <source :src="item.video"/>
        </video>
        <img v-lazy="item.icon" alt="ad" v-else/>
        <div class="cardDec" :style="getStyleConfig('adDec',item)">
          <p v-if="testDate(item.date)">{{ item.name }}</p>
          <countDown :time="item.date" v-else></countDown>
        </div>
      </div>
    </div>
    <div class="pageNews">
      <div class="itemInfo">
        <div class="infoHead">
          <div class="infoIcon">
            <div class="bkIcon" :style="iconColor">
              <img src="../../../assets/img/site/info.png" alt="" />
            </div>
            <p>{{pageLang === 1?'赛事公告/新闻':'Announcement/News'}}</p>
          </div>
          <span @click="linkTo('list')">{{pageLang === 1?'查看更多':'More >>'}}</span>
        </div>
        <div class="infoGroup">
          <div class="news" v-for="(item, index) in actArray" :key="index">
            <div class="dateItem">
              <img :src="imgArray[item.num]" alt="" />
              <div class="dateInfo">
                <span>{{ getTime(item.createTime, 1) }}</span>
                <span>{{ getTime(item.createTime, 0) }}</span>
              </div>
            </div>
            <div class="newsItem" @click="linkTo(item.link)">
              <p class="newsTitle">{{ item.name }}</p>
              <div class="newsCon">
                <p>{{ item.dec }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="itemCard">
        <div class="cardInfo" v-for="(item,index) in getAdList(2)" :key="index" @click="linkTo(item.link)">
          <video controls="controls" v-if="item.video">
            <source :src="item.video"/>
          </video>
          <img :src="item.icon" alt="ad" v-else />
          <div class="cardDec" :style="getStyleConfig('adDec',item)">
            <p v-if="testDate(item.date)">{{ item.name }}</p>
            <countDown :time="item.date" v-else></countDown>
          </div>
        </div>
      </div>
    </div>
    <div class="photoGroup">
      <div class="photoHead">
        <div class="infoIcon">
          <div class="bkIcon" :style="iconColor">
            <img src="../../../assets/img/site/show.png" alt="" />
          </div>
          <p>{{pageLang === 1?'精彩瞬间':'Photo Highlights'}}</p>
        </div>
        <span @click="linkTo('alts')">{{pageLang === 1?'查看更多':'More >>'}}</span>

      </div>
      <div class="photoCard">
        <div class="cell-item" v-for="(item, index) in coverAtlas" :key="index" :style="{ backgroundImage: 'url('+ item.url +')'}" @click="openImgMod(item.url)">
<!--          <img :src="item.url" @click="openImgMod(item.url)"/>-->
          <!--<img v-lazy="item.url" @click="openImgMod(item.url)">-->
        </div>
      </div>
    </div>
    <div class="photoGroup" v-if="coverVideos.length > 0">
      <div class="photoHead">
        <div class="infoIcon">
          <div class="bkIcon" :style="iconColor">
            <img src="../../../assets/img/site/show.png" alt="" />
          </div>
          <p>{{pageLang === 1?'精彩视频':'Video Highlights'}}</p>
        </div>
        <span @click="linkTo('videos')">{{pageLang === 1?'查看更多':'More >>'}}</span>
      </div>
      <div class="photoCard">
        <div class="cell-item" v-for="(item, index) in coverVideos" :key="index">
          <img :src="item.url" alt="" v-if="item.type === 1" />
          <video controls="controls" v-else>
            <source :src="item.url"/>
          </video>
        </div>
      </div>
    </div>
    <div class="logoGroup">
      <div class="logoCard" v-for="(item, index) in webSite.sponsorList" :key="index" :class="'logoT'+item.lv">
        <div class="logoTitle">
          <img src="../../../assets/img/site/logo.png" alt="" />
          <p>{{ item.name }}</p>
        </div>
        <div class="logoItem">
          <div class="logoSpr" v-for="(logo,index) in item.logoList"  :style="{ backgroundImage: 'url('+ logo.logo +')'}" :key="index" @click="linkTo(logo.link)">
            <!--<img :src="logo.logo" alt="" @click="linkTo(logo.link)" />-->
          </div>
        </div>
      </div>
    </div>
    <div class="imgMod" v-if="actUrl">
      <div class="imgCon">
        <Icon type="md-close-circle" @click="actUrl = ''" />
        <img :src="actUrl" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
  import home from "./home"
  export default home
</script>
